import React from 'react';

export default function CloseIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L21.3536 20.6464C21.5488 20.8417 21.5488 21.1583 21.3536 21.3536C21.1583 21.5488 20.8417 21.5488 20.6464 21.3536L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z"
				fill="#707578"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.3536 2.64645C21.5488 2.84171 21.5488 3.15829 21.3536 3.35355L3.35355 21.3536C3.15829 21.5488 2.84171 21.5488 2.64645 21.3536C2.45118 21.1583 2.45118 20.8417 2.64645 20.6464L20.6464 2.64645C20.8417 2.45118 21.1583 2.45118 21.3536 2.64645Z"
				fill="#707578"
			/>
		</svg>
	);
}

import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import {
	settingsReducer,
	initialSettings,
	Settings,
	SettingsAction
} from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';

export interface StateContextType {
	error: TwilioError | Error | null;
	user?: null | {
		displayName: undefined;
		photoURL: undefined;
		passcode?: string;
	};
	setError(error: TwilioError | Error | null): void;
	getToken(
		name: string,
		room: string,
		passcode?: string
	): Promise<{ room_type: RoomType; token: string }>;
	signIn?(passcode?: string): Promise<void>;
	signOut?(): Promise<void>;
	isAuthReady?: boolean;
	isFetching: boolean;
	activeSinkId: string;
	setActiveSinkId(sinkId: string): void;
	settings: Settings;
	dispatchSetting: React.Dispatch<SettingsAction>;
	roomType?: RoomType;
	updateRecordingRules(
		room_sid: string,
		rules: RecordingRules
	): Promise<object>;
	isGalleryViewActive: boolean;
	setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
	maxGalleryViewParticipants: number;
	setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
	isKrispEnabled: boolean;
	setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	isKrispInstalled: boolean;
	setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
	const [error, setError] = useState<TwilioError | null>(null);
	const [isFetching, setIsFetching] = useState(false);
	const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState(
		'gallery-view-active-key',
		true
	);
	const [activeSinkId, setActiveSinkId] = useActiveSinkId();
	const [settings, dispatchSetting] = useReducer(
		settingsReducer,
		initialSettings
	);
	const [roomType, setRoomType] = useState<RoomType>();
	const [
		maxGalleryViewParticipants,
		setMaxGalleryViewParticipants
	] = useLocalStorageState('max-gallery-participants-key', 6);

	const [isKrispEnabled, setIsKrispEnabled] = useState(false);
	const [isKrispInstalled, setIsKrispInstalled] = useState(false);

	let contextValue = {
		error,
		setError,
		isFetching,
		activeSinkId,
		setActiveSinkId,
		settings,
		dispatchSetting,
		roomType,
		isGalleryViewActive,
		setIsGalleryViewActive,
		maxGalleryViewParticipants,
		setMaxGalleryViewParticipants,
		isKrispEnabled,
		setIsKrispEnabled,
		isKrispInstalled,
		setIsKrispInstalled
	} as StateContextType;

	if (process.env.REACT_APP_SET_AUTH === 'passcode') {
		contextValue = {
			...contextValue,
			...usePasscodeAuth() // eslint-disable-line react-hooks/rules-of-hooks
		};
	} else {
		contextValue = {
			...contextValue,
			getToken: async (identity, roomName) => {
				const endpoint = 'https://video.chekkit.io/token';

				return fetch(endpoint, {
					method: 'POST',
					headers: {
						'content-type': 'application/json'
					},
					body: JSON.stringify({
						identity,
						roomName,
						create_conversation:
							process.env
								.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !==
							'true'
					})
				}).then(res => res.json());
			},
			updateRecordingRules: async (room_sid, rules) => {
				const endpoint =
					process.env.REACT_APP_TOKEN_ENDPOINT || '/recordingrules';

				return fetch(endpoint, {
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ room_sid, rules }),
					method: 'POST'
				})
					.then(async res => {
						const jsonResponse = await res.json();

						if (!res.ok) {
							const recordingError = new Error(
								jsonResponse.error?.message ||
									'There was an error updating recording rules'
							);
							recordingError.code = jsonResponse.error?.code;
							return Promise.reject(recordingError);
						}

						return jsonResponse;
					})
					.catch(err => setError(err));
			}
		};
	}

	const getToken: StateContextType['getToken'] = (name, room) => {
		setIsFetching(true);
		return contextValue
			.getToken(name, room)
			.then(res => {
				setRoomType(res.room_type);
				setIsFetching(false);
				return res;
			})
			.catch(err => {
				setError(err);
				setIsFetching(false);
				return Promise.reject(err);
			});
	};

	const updateRecordingRules: StateContextType['updateRecordingRules'] = (
		room_sid,
		rules
	) => {
		setIsFetching(true);
		return contextValue
			.updateRecordingRules(room_sid, rules)
			.then(res => {
				setIsFetching(false);
				return res;
			})
			.catch(err => {
				setError(err);
				setIsFetching(false);
				return Promise.reject(err);
			});
	};

	return (
		<StateContext.Provider
			value={{ ...contextValue, getToken, updateRecordingRules }}
		>
			{props.children}
		</StateContext.Provider>
	);
}

export function useAppState() {
	const context = useContext(StateContext);
	if (!context) {
		throw new Error('useAppState must be used within the AppStateProvider');
	}
	return context;
}
